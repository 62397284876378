import React, { CSSProperties } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import c from 'classnames';
import * as styles from './styles.module.scss';

type DynamicImageProps = {
  imageName?: string;
  additionalClass: string;
  objectFit?: CSSProperties['objectFit'];
  loading?: 'eager' | 'lazy';
};

const DynamicImage = ({
  imageName = '',
  additionalClass,
  objectFit = 'cover',
  loading = 'lazy',
}: DynamicImageProps) => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { internal: { mediaType: { regex: "/image/" } } }) {
        edges {
          node {
            name
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      }
    }
  `);

  const match = data.images.edges.find(({ node }: { node: any }) => imageName === node.name);

  if (!match) return null;

  const {
    node: { name, childImageSharp, publicURL },
  } = match;
  return childImageSharp ? (
    <GatsbyImage
      objectFit={objectFit}
      image={childImageSharp.gatsbyImageData}
      alt={name}
      className={c(styles.image, additionalClass)}
      loading={loading}
    />
  ) : (
    <img src={publicURL} alt={name} className={c(styles.image, additionalClass)}></img>
  );
};

export default DynamicImage;
