import React from 'react';
import { ContentSliderItem } from '..';
import DynamicImage from '../../../ui/DynamicImage';
import Headline from '../../../ui/Headline';
import * as styles from './styles.module.scss';

type SliderItemProps = {
  item: ContentSliderItem;
};

const SliderItem = ({ item }: SliderItemProps) => (
  <div className={styles.contentSlider}>
    <div className={styles.contentSliderContainer}>
      <Headline level={4} additionalClass={styles.contentSliderHeading}>
        {item.title}
      </Headline>
      <div className={styles.insuranceImage}>
        {item.image && <DynamicImage additionalClass={styles.image} imageName={item.image} />}
      </div>
      <div className={styles.contentOneContainer}>
        <div className={styles.contentPara}>
          <Headline level={6} additionalClass={styles.mainHeading}>
            {item.headline}
          </Headline>
          <div className={styles.contentParaText}>
            <p>{item.text}</p>
          </div>

          <a href={item.btnTarget} target="_blank" className={styles.btn} rel="noreferrer">
            {item.btnText}
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default SliderItem;
